<template>
  <div class="top-player" :class="{ fs: showFSPlayer }">
    <div v-if="isDesktop" class="bg"></div>
    <div v-else class="bg">
      <nuxt-link to="/" class="item">
        <img src="/static_files/icons/home.png" alt="Home Page">
        <strong>{{ lang('main', 'ru') }}</strong>
      </nuxt-link>
      <nuxt-link to="/collection/charts" class="item">
        <img src="/static_files/icons/chart.png" alt="Charts Page">
        <strong>{{ lang('charts', 'ru') }}</strong>
      </nuxt-link>
      <nuxt-link to="/collection/radio" class="item">
        <img src="/static_files/icons/radio.png" alt="Radio Page">
        <strong>{{ lang('radio', 'ru') }}</strong>
      </nuxt-link>
      <nuxt-link to="/search" class="item">
        <img src="/static_files/icons/loupe.png" alt="Search Page">
        <strong>{{ lang('search', 'ru') }}</strong>
      </nuxt-link>
      <div @click="showProfileMenu = !showProfileMenu" class="item">
        <img src="/static_files/icons/user.png" alt="Profile Page">
        <strong>{{ lang('profile', 'ru') }}</strong>
      </div>
    </div>
    <div class="wrapper">
      <div id="player"></div>
    </div>

    <div v-if="isDesktop" class="actions">
      <template v-if="currentTrack">
        <Favs :payload="{
          id: currentTrack.track_id,
          title: currentTrack.title,
          subtitle: currentTrack.subtitle,
          poster: currentTrack.poster,
          type: 'player-track',
        }" />
        <a :href="currentTrack.file" download class="download"></a>
        <div @click="share(currentTrack)" class="share">
          <input
            :data-share-id="currentTrack.track_id"
            :value="`https://muzzilka.ru/t/${currentTrack.track_id}`"
            type="text"
            readonly
          />
        </div>
      </template>
      <div v-if="$route.params.type !== 'radio'" @click="setHQ()" :class="{ active: hq }" class="hq">HQ</div>
      <div @click="showTracksHistory = !showTracksHistory" :class="{ active: showTracksHistory }" class="history"></div>
    </div>

    <div v-show="showTracksHistory" class="tracks-history">
      <div v-if="tracksHistory.length" class="tracks">
        <div
          v-for="item in tracksHistory"
          :key="`tph-${item.track_id}`"
          @click="setTrack(item)"
          :class="{ active: currentTrack && currentTrack.track_id === item.track_id}"
          class="item"
        >
          <div class="cover">
            <img :src="item.poster" alt="">

            <div
              v-if="currentTrack && currentTrack.track_id === item.track_id"
              id="bars"
            >
              <div class="wrapper">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </div>
            </div>
          </div>
          <div class="info">
            <strong>
              {{ item.title }}
            </strong>
            <span>
              {{ item.subtitle }}
            </span>
          </div>
          <div class="duration">
            {{ item.duration }}
          </div>
          <div onclick="event.stopPropagation();" class="more"></div>
        </div>
      </div>
      <div v-else>
        Ваш плейлист пуст
      </div>
      <div class="more-tracks"></div>
    </div>

    <transition name="toTop">
      <div v-if="showProfileMenu" class="profile-menu">
        <div @click="showProfileMenu = false" class="close"></div>
        
        <nuxt-link to="/profile/favs">{{ lang('favorites', 'ru') }}</nuxt-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Favs: () => import('~/components/Favs.vue'),
  },
  watch: {
    changeRoute(val) {
      this.showProfileMenu = false
    },
    tracksHistory(val) {
      if (this.playerState === 'stop') this.setTrack(val[0])
    },
    showTracksHistory(val) {
      const contentElem = document.querySelector('.main')

      if (val) {
        contentElem.classList.add('show-tracks-history')
      } else {
        contentElem.classList.remove('show-tracks-history')
      }
    },
  },
  computed: {
    ...mapState(['tracksHistory', 'player', 'isDesktop', 'currentTrack', 'playerState']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      showTracksHistory: false,
      listenTracks: [],
      hq: false,
      loading: false,
      shufflePlay: false,
      showFSPlayer: false,
      showProfileMenu: false,
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = `/static_files/playerjs${this.isDesktop ? '-d' : '-m'}.js`
    document.body.appendChild(script)

    setTimeout(() => {
      this.playerInit()
    }, 100)

    const hq = localStorage.getItem('hq')
    if (hq) {
      this.hq = true
    }
  },
  methods: {
    share() {
      if (!this.isDesktop && navigator.share) {
        navigator.share({
          title: `Слушай и скачивай крутой трек на muZzilka.ru`,
          text: `${this.currentTrack.title} - ${
            this.currentTrack.subtitle
          }`,
          url: `https://mizzilka.ru/t/${this.currentTrack.track_id}`,
        })
        .then(() => console.log('Successfully share'))
        .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('Share not supported on this browser, do it the old way.')
        const input = document.querySelector(`[data-share-id="${this.currentTrack.track_id}"]`)
        if (input) {
          input.select()
          document.execCommand('copy')
          this.$store.commit('setError', { status: 'success', message: 'Link copied', timeout: 2000 })
        }
      }
    },
    setHQ() {
      const hq = localStorage.getItem('hq')

      if (hq) {
        localStorage.removeItem('hq')
      } else {
        localStorage.setItem('hq', 'true')
        this.hq = true
      }

      location.reload()
    },
    playerInit() {
      this.loading = true

      const params = {
        id: 'player',
        file: [{
          id: 'muzzilka',
          title: 'Плеер в режиме ожидания',
          subtitle: 'MuzZilka',
          file: '/static_files/uwu.mp3',
          poster: '/static_files/no-cover.jpg',
          track_id: 'muzzilka',
        }],
        autonext: 1,
      }

      /* eslint-disable */
      const playerJS = new Playerjs(params)
      this.$store.commit('setPlayer', playerJS) 

      const player = document.getElementById("player")
      // console.log('player', player)

      if (player) {
        player.addEventListener("play", (event) => {
          console.log('play')

          this.$store.commit('setPlayerState', 'play')
          
          const playlistId = this.player.api("playlist_id")
          // console.log('playlistId', playlistId)
          
          // console.log('this.tracksHistory', this.tracksHistory)
          if (!this.tracksHistory.length) return
          
          const currentTrackIndex = this.tracksHistory.findIndex(el => el.pjs_id === playlistId)
          // console.log('currentTrackIndex', currentTrackIndex)

          if (currentTrackIndex < 0) {
            this.$store.commit('setCurrentTrack', null)
            return
          }
          
          const currentTrack = this.tracksHistory[currentTrackIndex]
          // console.log('this.currentTrack', this.currentTrack)

          this.$store.commit('setCurrentTrack', currentTrack)

          if (!this.listenTracks.includes(currentTrack.track_id)) {
            this.listenTracks.push(currentTrack.track_id)
          }

          if (
            this.listenTracks.length === this.tracksHistory.length - 1 ||
            currentTrackIndex === this.tracksHistory.length - 1 ||
            currentTrackIndex === this.tracksHistory.length - 2
          ) {
            this.$store.commit('setLastTrack', true)
          }
        })

        player.addEventListener("new", (event) => {
          console.log('new')
          // console.log('title', this.player.api("title"))
          // console.log('playlist_id', this.player.api("playlist_id"))
          // console.log('playlist_title', this.player.api("playlist_title"))

        })

        player.addEventListener("pause", (event) => {
          console.log('pause')

          this.$store.commit('setPlayerState', 'pause')
        })

        player.addEventListener("end", (event) => {
          console.log('end')

          this.$store.commit('setPlayerState', 'end')

          if (this.tracksHistory.length) {
            if (this.shufflePlay) {
              // const currentTrackIndex = this.tracksHistory.findIndex(el => el.track_id === this.currentTrack.track_id)
              const rand = Math.floor(Math.random() * this.tracksHistory.length)
              if (this.tracksHistory[rand]) {
                this.setTrack(this.tracksHistory[rand])
              }
            } else {
              const currentTrackIndex = this.tracksHistory.findIndex(el => el.track_id === this.currentTrack.track_id)
              if (this.tracksHistory[currentTrackIndex + 1]) {
                this.setTrack(this.tracksHistory[currentTrackIndex + 1])
              }
            }
          }
        })

        player.addEventListener("prev", (event) => {
          console.log('prev')

          this.$store.commit('setPlayerState', 'prev')
        })

        player.addEventListener("next", (event) => {
          console.log('next')

          this.$store.commit('setPlayerState', 'next')
        })

        player.addEventListener("stop", (event) => {
          console.log('stop')

          this.$store.commit('setPlayerState', 'stop')
        })

        // player.addEventListener("time", (event) => {
        //   console.log('time')
        // })

        player.addEventListener("prevEvent", (event) => {
          console.log('prevEvent')

          if (this.tracksHistory.length) {
            const currentTrackIndex = this.tracksHistory.findIndex(el => el.track_id === this.currentTrack.track_id)
            if (this.tracksHistory[currentTrackIndex - 1]) {
              this.setTrack(this.tracksHistory[currentTrackIndex - 1])
            }
          }
        })

        player.addEventListener("nextEvent", (event) => {
          console.log('nextEvent')

          if (this.tracksHistory.length) {
            const currentTrackIndex = this.tracksHistory.findIndex(el => el.track_id === this.currentTrack.track_id)
            if (this.tracksHistory[currentTrackIndex + 1]) {
              this.setTrack(this.tracksHistory[currentTrackIndex + 1])
            }
          }
        })

        player.addEventListener("shuffleEvent", (event) => {
          console.log('shuffleEvent')

          this.shufflePlay = !this.shufflePlay
        })

        player.addEventListener("click", (event) => {
          console.log('click')

          // this.showFSPlayer = !this.showFSPlayer

          // if (!this.isDesktop && this.currentTrack) this.$router.push(`/track/${this.currentTrack.track_id}`)
        })
      }

      this.loading = false
    },
    async setTrack(track) {
      if (this.loading) return

      if (track.file.includes('/uwu.mp3')) {
        this.$store.commit('setTrackLoadingId', track.track_id)
        this.$store.commit('setGLoading', true)
        this.loading = true
        const { data } = await this.$axios.get(`/api/track-url?id=${track.track_id}`)
        this.loading = false
        this.$store.commit('setGLoading', false)

        if (!data.error) {
          track.file = data
          this.player.api("play", [track])
        }
      } else {
        this.player.api("play", [track])
      }
    },
    // playTrack(trackId) {
    //   this.player.api("playlist", this.tracksHistory)

    //   this.player.api("play", `id:${trackId}`)
    // },
  },
}
</script>

<style lang="stylus">
.top-player
  position: fixed
  top: 0px
  left: 320px
  width: 100%
  height: 65px
  text-align: center
  border-bottom: 1px solid #ccc
  z-index: 5
  .tracks-history
    position: fixed
    top: 65px
    right: 0px
    width: 340px
    height: calc(100vh - 65px)
    padding: 10px
    background-color: #fff
    border-left: 1px solid #ccc
    overflow-y: auto
  .wrapper
    padding: 0px 240px 0px 120px
    #player
      width: calc(100% - 340px)
      height: 64px
      box-shadow: none !important
      #oframeplayer
        background: rgba(255, 255, 255, 0) !important
  .bg
    position: absolute
    width: 100%
    height: 100%
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.85)
    backdrop-filter: blur(20px)
    -webkit-backdrop-filter: blur(20px)
  .actions
    position: fixed
    top: 20px
    right: 24px
    display: grid
    grid-template-columns: repeat(5, 32px)
    grid-gap: 8px
    justify-items: end
    align-items: center
    z-index: 11
    .share
      padding: 14px
      width: 32px
      height: 32px
      background-image: url('/static_files/icons/share.png')
      background-size: 18px
      background-repeat: no-repeat
      background-position: center
      opacity: 0.65
      border-radius: 8px
      cursor: pointer
      &:hover
        background-color: #eee
        opacity: 1
      input
        position: absolute
        top: -9999px
    .download
      padding: 14px
      width: 32px
      height: 32px
      background-image: url('/static_files/icons/download.png')
      background-size: 18px
      background-repeat: no-repeat
      background-position: center
      opacity: 0.65
      border-radius: 8px
      cursor: pointer
      &:hover
        background-color: #eee
        opacity: 1
    .favorite
      padding: 6px
      width: 32px
      height: 32px
      opacity: 0.65
      border-radius: 8px
      &:hover
        background-color: #eee
        opacity: 1
      img
        width: 14px
        height: 14px
    .hq
      font-weight: 400
      font-size: 1.4rem
      text-transform: uppercase
      text-align: center
      cursor: pointer
      border-radius: 8px
      transition: color 0.2s ease
      opacity: 0.65
      &:hover
        color: #f9243b
        opacity: 1
      &.active
        color: #f9243b // dc3232
        font-weight: 500
        opacity: 1
    .history
      padding: 14px
      width: 32px
      height: 32px
      background-image: url('/static_files/icons/list.png')
      background-size: 18px
      background-repeat: no-repeat
      background-position: center
      opacity: 0.65
      border-radius: 8px
      cursor: pointer
      transition: all 0.2s ease
      &:hover
        background-color: #eee
        opacity: 1
      &.active
        background-color: #eee
  .tracks
    margin: 0px
    padding-bottom: 20px
    .item
      position: relative
      padding: 8px
      display: grid
      grid-template-columns: 40px auto 40px
      grid-gap: 10px
      align-items: center
      line-height: 18px
      border-radius: 4px
      cursor: pointer
      &:hover
        color: #f9243b
      &:hover > .more
        display: inline-block
      &:hover > .duration
        display: none
      &.active
        background-color: #f9243b
        .duration
          color: #fff
        .more
          background-image: url('/static_files/icons/more-2.png')
        .info
          color: #fff
          border-color: rgba(230, 50, 50, 1)
          span
            color: #fff
      .cover
        position: relative
        width: 40px
        height: 40px
        background-color: #eee
        background-image: url('/static_files/no-cover.jpg')
        background-size: 40px
        overflow: hidden
        border-radius: 4px
        img
          width: 100%
          min-height: 100%
          object-fit: cover
        #bars
          width: 40px
          height: 40px
      .info
        border-bottom: 1px solid #ccc
        display: grid
        align-items: center
        strong, span
          display: block
          width: 190px
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
          text-align: start
        strong
          font-size: 1.4rem
        span
          font-size: 1.2rem
          color: rgba(0, 0, 0, 0.5)
          // text-transform: uppercase
      .duration
        font-size: 1.4rem
        color: rgba(0, 0, 0, 0.5)
      .more
        display: none
        justify-self: end
        width: 12px
        height: 12px
        margin-right: 5px
        background-image: url('/static_files/icons/more-3.png')
        background-size: 12px
        cursor: pointer
</style>
